import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FreeriderUser } from '../../types/FreeriderUser'

export type UserStatus = {
  isPending: boolean
  isWarned: boolean
  isSuspended: boolean
  isSuspendedByOldFreerider: boolean
}

export interface UserState {
  user: FreeriderUser | null
  status: UserStatus
}

const initialState: UserState = {
  user: null,
  status: {
    isPending: false,
    isWarned: false,
    isSuspended: false,
    isSuspendedByOldFreerider: false,
  },
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ user: FreeriderUser; status: Partial<UserStatus> }>) => {
      const { user, status } = action.payload
      return {
        ...state,
        user,
        status: {
          ...state.status,
          ...status,
        },
      }
    },
  },
})

export const { setUser } = userSlice.actions

export const userReducer = userSlice.reducer
