import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { Location, TransportRoutes } from '../../types/transportRoutes.types'

interface FromAndToLocation {
  selectedFromLocation: string
  selectedToLocation: string
}

interface SearchRouteState {
  locations: Location[]
  transportRoutes: TransportRoutes[] | undefined
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  fromAndToLocation: FromAndToLocation
}

const initialState: SearchRouteState = {
  locations: [],
  transportRoutes: [],
  loading: 'idle',
  fromAndToLocation: {
    selectedFromLocation: '',
    selectedToLocation: '',
  },
}

const ENDPOINT_LOCATIONS: string = process.env.ENDPOINT_LOCATIONS || ''

export const fetchAllLocations = createAsyncThunk<Location[]>('locations/fetchAll', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get<Location[]>(ENDPOINT_LOCATIONS)
    const uniqueLocations = Array.from(
      (response.data as Location[])
        .reduce((map: Map<string, Location>, obj: Location) => map.set(obj.name, obj), new Map())
        .values(),
    )
    uniqueLocations.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

    return uniqueLocations
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      return rejectWithValue(err.response.data)
    }
    if (err instanceof Error) {
      return rejectWithValue(err.message)
    }
    throw err
  }
})

const searchRouteSlice = createSlice({
  name: 'searchRoute',
  initialState,
  reducers: {
    setFromAndToLocation: (state, action: PayloadAction<FromAndToLocation>) => {
      state.fromAndToLocation = action.payload
    },
    setAvailableCars: (state, action: PayloadAction<TransportRoutes[] | undefined>) => {
      state.transportRoutes = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLocations.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(fetchAllLocations.fulfilled, (state, action) => {
        state.locations = action.payload
        state.loading = 'succeeded'
      })
      .addCase(fetchAllLocations.rejected, (state, action) => {
        if (action.payload) {
          console.error(action.payload)
        } else {
          console.error(action.error.message)
        }
        state.loading = 'failed'
      })
  },
})

export const { setFromAndToLocation, setAvailableCars } = searchRouteSlice.actions
export default searchRouteSlice.reducer
export type { Location, FromAndToLocation, SearchRouteState }
