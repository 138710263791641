import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { bookingListReducer, carListReducer, uiStateReducer, userReducer } from './slices'

import bookingFlowSliceReducer from '../features/booking-stepper/bookingFlowSlice'
import searchRouteReducer from '../features/search-route/searchRouteSlice'
import localeSliceReducer from '../features/localization/localeSlice'
import getInitialLocaleMiddleware from '../features/localization/getInitialLocaleMiddleware'

const store = configureStore({
  reducer: {
    carList: carListReducer,
    searchRoute: searchRouteReducer,
    bookingList: bookingListReducer,
    bookingFlow: bookingFlowSliceReducer,
    user: userReducer,
    uiState: uiStateReducer,
    locale: localeSliceReducer,
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(getInitialLocaleMiddleware),
})
store.dispatch({ type: '@@INIT' })

export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
export type RootState = ReturnType<typeof store.getState>

export default store
