import { Middleware } from '@reduxjs/toolkit'

import getInitialLocale from './getInitialLocale'
import { Locale } from '../../types'

const getInitialLocaleMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  if (action.type === '@@INIT') {
    const locale: Locale = getInitialLocale()
    storeAPI.dispatch({ type: 'locale/setLocale', payload: locale })
  }

  return next(action)
}
export default getInitialLocaleMiddleware
