import { defaultLanguage } from '../../../prismic-configuration'
import { Country, Language, Locale } from '../../types'

const getInitialLocale = (): Locale => {
  const [defaultLang, defaultCountry] = defaultLanguage.split('-')

  if (typeof window !== 'undefined') {
    const { hostname } = window.location

    const storedLocale = localStorage.getItem('userLocale')
    const userLocale = storedLocale ? JSON.parse(storedLocale) : null

    switch (true) {
      case hostname.endsWith('.no'):
        if (userLocale?.country === 'no') {
          return userLocale
        }
        return { language: 'no', country: 'no' }

      case hostname.endsWith('.se'):
        if (userLocale?.country === 'se') {
          return userLocale
        }
        return { language: 'sv', country: 'se' }

      // FOR LOCAL DEVELOPMENT
      // switch values to 'se/sv' or 'no/no'
      case hostname.endsWith('localhost'):
        if (userLocale?.country === 'se') {
          return userLocale
        }
        return { language: 'sv', country: 'se' }

      default:
        break
    }
  }
  return {
    language: defaultLang as Language,
    country: defaultCountry as Country,
  }
}

export default getInitialLocale
