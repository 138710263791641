import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'

import { TransportRoute } from '../../types/transportRoutes.types'

export interface CarListState {
  availableRoutes: TransportRoute[]
  filteredRoutes: TransportRoute[]
  availableRoutesAmount: number
  selectedRoute: TransportRoute
  drawerOpen: boolean
}

const initialState: CarListState = {
  availableRoutes: [],
  filteredRoutes: [],
  availableRoutesAmount: 0,
  selectedRoute: {
    id: 0,
    transportOfferId: 0,
    pickupLocation: {
      tracCode: '',
      name: '',
      country: '',
      emailAddress: '',
      address: '',
      city: '',
      phoneNumber: '',
      regularOpeningHours: {},
      geoLat: 0,
      geoLon: 0,
      infoTextDropOffEnglish: '',
      infoTextDropOffNative: '',
      infoTextLocationEnglish: '',
      infoTextLocationNative: '',
      infoTextMiscEnglish: '',
      infoTextMiscNative: '',
      infoTextParkingEnglish: '',
      infoTextParkingNative: '',
      infoTextReturnEnglish: '',
      infoTextReturnNative: '',
    },
    returnLocation: {
      tracCode: '',
      name: '',
      country: '',
      emailAddress: '',
      address: '',
      city: '',
      phoneNumber: '',
      regularOpeningHours: {},
      geoLat: 0,
      geoLon: 0,
      infoTextDropOffEnglish: '',
      infoTextDropOffNative: '',
      infoTextLocationEnglish: '',
      infoTextLocationNative: '',
      infoTextMiscEnglish: '',
      infoTextMiscNative: '',
      infoTextParkingEnglish: '',
      infoTextParkingNative: '',
      infoTextReturnEnglish: '',
      infoTextReturnNative: '',
    },
    priorityOrder: 0,
    distance: 0,
    originalDistance: 0,
    travelTime: 0,
    originalTravelTime: 0,
    latestReturn: '',
    availableAt: '',
    expireTime: '',
    carModel: '',
    publicDescription: '',
    publicInformation: '',
  },
  drawerOpen: false,
}

const carListSlice: Slice<CarListState> = createSlice({
  name: 'car-list',
  initialState,
  reducers: {
    setAvailableRoutes: (state, action: PayloadAction<TransportRoute[]>) => ({
      ...state,
      availableRoutes: action.payload,
    }),
    setFilteredRoutes: (state, action: PayloadAction<TransportRoute[]>) => ({
      ...state,
      filteredRoutes: action.payload,
    }),
    setAvailableRoutesAmount: (state, action: PayloadAction<number>) => ({
      ...state,
      availableRoutesAmount: action.payload,
    }),
    setSelectedRoute: (state, action: PayloadAction<TransportRoute>) => ({
      ...state,
      selectedRoute: action.payload,
    }),
    resetSelectedRoute: (state) => ({
      ...state,
      selectedRoute: initialState.selectedRoute,
    }),
    showSelectedCarDrawer: (state, action: PayloadAction<boolean>) => ({
      ...state,
      drawerOpen: action.payload,
    }),
  },
})

export const {
  setAvailableRoutes,
  setFilteredRoutes,
  setAvailableRoutesAmount,
  setSelectedRoute,
  resetSelectedRoute,
  showSelectedCarDrawer,
} = carListSlice.actions

export const carListReducer = carListSlice.reducer
