import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Snackbar {
  message: string
  type: 'success' | 'error' | ''
}

interface Banner {
  show: boolean
  title: string
  body: string
  linkButtonLabel: string
  linkButtonLink: {
    link_type: string
    type: string
    url: string
  }
  closeButtonLabel: string
}

interface Dialog {
  show: boolean
  title: string
  body: string
  contact?: { contactText: string; mailAdress: string }
  buttonText: string
}

export interface UiState {
  showNavigationComponents: boolean
  showSnackbar: boolean
  snackbar: Snackbar
  banner: Banner
  dialog: Dialog
}

const initialState: UiState = {
  showNavigationComponents: true,
  showSnackbar: false,
  snackbar: {
    message: '',
    type: '',
  },
  banner: {
    show: false,
    title: '',
    body: '',
    linkButtonLabel: '',
    linkButtonLink: {
      link_type: '',
      type: '',
      url: '',
    },
    closeButtonLabel: '',
  },
  dialog: {
    show: false,
    title: '',
    body: '',
    contact: undefined,
    buttonText: '',
  },
}

const uiStateSlice = createSlice({
  name: 'ui-state',
  initialState,
  reducers: {
    setBanner: (state, action: PayloadAction<Banner>) => {
      state.banner.show = action.payload.show
      state.banner.title = action.payload.title
      state.banner.body = action.payload.body
      state.banner.linkButtonLabel = action.payload.linkButtonLabel
      state.banner.linkButtonLink = action.payload.linkButtonLink
      state.banner.closeButtonLabel = action.payload.closeButtonLabel
    },
    resetBanner: (state) => {
      state.banner = initialState.banner
    },
    setDialog: (state, action: PayloadAction<Dialog>) => {
      state.dialog.show = action.payload.show
      state.dialog.title = action.payload.title
      state.dialog.body = action.payload.body
      state.dialog.contact = action.payload.contact
      state.dialog.buttonText = action.payload.buttonText
    },
    resetDialog: (state) => {
      state.dialog = initialState.dialog
    },
    setShowNavigationComponents: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showNavigationComponents: action.payload,
    }),
    setSnackbar: (state, action: PayloadAction<Snackbar>) => {
      state.showSnackbar = true
      state.snackbar.message = action.payload.message
      state.snackbar.type = action.payload.type
    },
    resetSnackbar: (state) => {
      state.showSnackbar = initialState.showSnackbar
    },
  },
})

export const {
  setShowNavigationComponents,
  setBanner,
  resetBanner,
  setDialog,
  resetDialog,
  setSnackbar,
  resetSnackbar,
} = uiStateSlice.actions

export const uiStateReducer = uiStateSlice.reducer
