import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'

import { Reservation } from '../../types/Reservation'

export interface BookingListState {
  allBookings: {
    upcoming: Reservation[] | []
    previous: Reservation[] | []
  }
  selectedBooking: Reservation
  isActiveBooking: boolean
  drawerOpen: boolean
}

const initialState: BookingListState = {
  allBookings: {
    upcoming: [],
    previous: [],
  },
  selectedBooking: {
    reservationId: '',
    transportOfferId: 0,
    userId: 0,
    driver: null,
    car: {
      licenseNumber: '',
      model: '',
      group: '',
      location: {
        name: '',
        tracCode: '',
        country: '',
        emailAddress: '',
        address: '',
        city: '',
        phoneNumber: '',
        regularOpeningHours: {},
        geoLat: 0,
        geoLon: 0,
        infoTextDropOffEnglish: null,
        infoTextDropOffNative: null,
        infoTextLocationEnglish: null,
        infoTextLocationNative: null,
        infoTextMiscEnglish: null,
        infoTextMiscNative: null,
        infoTextParkingEnglish: null,
        infoTextParkingNative: null,
        infoTextReturnEnglish: null,
        infoTextReturnNative: null,
      },
      ready: false,
    },
    pickupLocation: {
      name: '',
      tracCode: '',
      country: '',
      emailAddress: '',
      address: '',
      city: '',
      phoneNumber: '',
      regularOpeningHours: {},
      geoLat: 0,
      geoLon: 0,
      infoTextDropOffEnglish: null,
      infoTextDropOffNative: null,
      infoTextLocationEnglish: null,
      infoTextLocationNative: null,
      infoTextMiscEnglish: null,
      infoTextMiscNative: null,
      infoTextParkingEnglish: null,
      infoTextParkingNative: null,
      infoTextReturnEnglish: null,
      infoTextReturnNative: null,
    },
    returnLocation: {
      name: '',
      tracCode: '',
      country: '',
      emailAddress: '',
      address: '',
      city: '',
      phoneNumber: '',
      regularOpeningHours: {},
      geoLat: 0,
      geoLon: 0,
      infoTextDropOffEnglish: null,
      infoTextDropOffNative: null,
      infoTextLocationEnglish: null,
      infoTextLocationNative: null,
      infoTextMiscEnglish: null,
      infoTextMiscNative: null,
      infoTextParkingEnglish: null,
      infoTextParkingNative: null,
      infoTextReturnEnglish: null,
      infoTextReturnNative: null,
    },
    pickupDate: '',
    returnDate: '',
    status: null,
    createdDate: '',
    updatedDate: '',
    countryCode: '',
    publicInformation: '',
    publicDescription: '',
    distance: 0,
    travelTime: 0,
  },
  isActiveBooking: false,
  drawerOpen: false,
}

const bookingListSlice: Slice<BookingListState> = createSlice({
  name: 'booking-list',
  initialState,
  reducers: {
    setAllBookings: (state, action: PayloadAction<{ upcoming: Reservation[] | []; previous: Reservation[] | [] }>) => ({
      ...state,
      allBookings: action.payload,
    }),
    setSelectedBooking: (state, action: PayloadAction<Reservation>) => ({
      ...state,
      selectedBooking: action.payload,
    }),
    setIsActiveBooking: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isActiveBooking: action.payload,
    }),
    resetSelectedBooking: (state) => ({
      ...state,
      selectedBooking: initialState.selectedBooking,
    }),
    showBookingDrawer: (state, action: PayloadAction<boolean>) => ({
      ...state,
      drawerOpen: action.payload,
    }),
  },
})

export const { setAllBookings, setSelectedBooking, setIsActiveBooking, resetSelectedBooking, showBookingDrawer } =
  bookingListSlice.actions

export const bookingListReducer = bookingListSlice.reducer
