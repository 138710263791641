import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { ThemeProvider } from '@hertz/odinui-web'

import * as React from 'react'
import { Provider } from 'react-redux'

import { msalConfig } from './src/authConfig'
import store from './src/store/store'
import './src/styles/FontFamily.css'

const msalInstance = new PublicClientApplication(msalConfig)

type Props = {
  element: JSX.Element
}

export const wrapRootElement = ({ element }: Props) => (
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <ThemeProvider brand="hertz">{element}</ThemeProvider>
    </MsalProvider>
  </Provider>
)
