exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-booking-flow-tsx": () => import("./../../../src/templates/BookingFlow.tsx" /* webpackChunkName: "component---src-templates-booking-flow-tsx" */),
  "component---src-templates-faq-and-rules-tsx": () => import("./../../../src/templates/FaqAndRules.tsx" /* webpackChunkName: "component---src-templates-faq-and-rules-tsx" */),
  "component---src-templates-my-bookings-tsx": () => import("./../../../src/templates/MyBookings.tsx" /* webpackChunkName: "component---src-templates-my-bookings-tsx" */),
  "component---src-templates-my-profile-tsx": () => import("./../../../src/templates/MyProfile.tsx" /* webpackChunkName: "component---src-templates-my-profile-tsx" */),
  "component---src-templates-not-found-tsx": () => import("./../../../src/templates/NotFound.tsx" /* webpackChunkName: "component---src-templates-not-found-tsx" */),
  "component---src-templates-start-tsx": () => import("./../../../src/templates/Start.tsx" /* webpackChunkName: "component---src-templates-start-tsx" */),
  "component---src-templates-terms-and-conditions-tsx": () => import("./../../../src/templates/TermsAndConditions.tsx" /* webpackChunkName: "component---src-templates-terms-and-conditions-tsx" */)
}

