import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Locale } from '../../types'

export interface LocaleState {
  locale: Locale
}

const initialState: LocaleState = {
  locale: {
    language: 'en',
    country: 'us',
  },
}

const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale: (store, action: PayloadAction<Locale>) => {
      store.locale = action.payload
    },
  },
})

export const { setLocale } = localeSlice.actions

export default localeSlice.reducer
